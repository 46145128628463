import Markdown from "./Markdown";
import {regexMap} from "../../utils/Settings";
import PropTypes from 'prop-types';
import {sourceTypesEnum} from "../../utils/SourceUtils";
import {useContext} from "react";
import {StoreContext} from "../../stores/StoreLoader";
import {hasHTML, stripImageTags} from "../../utils/StringUtilities";

export function traverseAndShorten(str, targetLen = 200) {
    const arr = str.split(" ");
    let newStr = "";
    let len = 0;

    for (let i = 0; i < arr.length; i++) {
        if (regexMap.mdLink.test(arr[i])) {
            newStr += arr[i] + " ";
            len += arr[i].match(/^\[.*\]/gmi)[0].length - 2;
        } else if (len <= targetLen) {
            newStr += arr[i] + " ";
            len += arr[i].length;
        } else {
            newStr += "...";
            break;
        }
    }

    return newStr;
}

const SocialMarkdown = props => {
    const {interfaceStore} = useContext(StoreContext);
    let text;

    switch (props.platform) { // now build description based on attribution
        case sourceTypesEnum.FACEBOOK:
            text = props.text
                .replace(regexMap.links, match => "[" + match + "](" + match + ")")
                .replace(regexMap.hashtags, match => "[*" + match + "*](https://www.facebook.com/hashtag/" + match.slice(1) + ")");
            if (props.shorten) {
                text = traverseAndShorten(text, props.shorten);
            }
            return <Markdown source={text} withStyles={true}/>;
        case sourceTypesEnum.TWITTER:
            text = props.text
                .replace(regexMap.links, match => "[" + match + "](" + match + ")") // format links
                .replace(regexMap.tags, match => "[*" + match + "*](https://www.twitter.com/" + match.slice(1) + ")") // format tags
                .replace(regexMap.hashtags, match => "[*" + match + "*](https://www.twitter.com/hashtag/" + match.slice(1) + ")");
            if (props.shorten) {
                traverseAndShorten(text, props.shorten);
            }
            return <Markdown source={text} withStyles={true}/>;
        case sourceTypesEnum.ICAL:
            text = props.text
                .replace(regexMap.links, match => "[" + match + "](" + match + ")") // format links
            if (props.shorten) {
                traverseAndShorten(text, props.shorten);
            }
            return <Markdown source={text} escapeHtml={false} withStyles={true} />;
        default:
            if (hasHTML(props.text)) {
                // events and rss feeds, at least, can contain html. also strip images because they look bad
                return <div dangerouslySetInnerHTML={{ __html: stripImageTags(props.text) }} />
            } else {
                return <Markdown withStyles={true} source={props.text} />
            }
    }
};

SocialMarkdown.propTypes = {
    platform: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
    shorten: PropTypes.oneOfType([PropTypes.number, PropTypes.bool]),
};

export default SocialMarkdown;
